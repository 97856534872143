/** @jsx jsx */
import { Fragment, useState } from 'react'
import { Box, Container, Flex, jsx } from 'theme-ui'
import ImageCarousel from '~/components/Generic/ImageCarousel'
import RelatedProducts from '~/components/Generic/RelatedProducts'
import ProductDetails from '~/components/Product/Details'
import ProductImages from '~/components/Product/Images'
import ProductQuote from '~/components/Product/Quote'
import ProductRecycle from '~/components/Product/Recycle'

const ProductPage = ({ product }) => {
  const { relatedProducts, quote, variants, recycle } = product

  // each product have at least one variant (enforced by Contentful model)
  const [variant, setVariant] = useState(variants[0])

  // get images variant if any otherwise fallback on product images
  const images = variant.images.length > 0 ? variant.images : product.images

  const collection = product.collection.find(collection => collection)

  return (
    <Fragment>
      <Container p={0}
        sx={{
          margin: '0 auto',
          maxWidth: 'calc(1200px + 3rem)',
          padding: [0, 0, '1.5rem'],
        }}
      >
        <Flex
          sx={{
            flexDirection: ['column', 'column', 'row'],
            marginTop: ['0', null, '32px']
          }}
        >
          <Box
            sx={{
              variant: 'responsive.mobileTablet',
              width: ['100%', '60%'],
              margin: 'auto',
              '& .carousel-slider': {
                overflow: 'visible'
              },
              '& .slider-wrapper': {
                overflow: 'visible'
              }
            }}
          >
            <ImageCarousel images={images} />
          </Box>
          <Box sx={{ variant: 'responsive.desktop', width: '100%' }}>
            <ProductImages images={images} />
          </Box>
          <ProductDetails
            product={product}
            collectionName={collection.slug}
            sx={{
              maxWidth: ['100%', '100%', '50%'],
              padding: ['0 1rem', '0 2rem'],
            }}
            handleVariantChange={variant => setVariant(variant)}
          />
        </Flex>
      </Container>
      <Container variant="fullWidth">
        {quote && <ProductQuote quote={quote} />}
      </Container>
      <Container>
        {relatedProducts && <RelatedProducts products={relatedProducts} />}
      </Container>

      {recycle && <ProductRecycle recycle={recycle} />}
    </Fragment>
  )
}

export default ProductPage
