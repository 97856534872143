/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, jsx } from 'theme-ui'
import CollapsiblePanel from '~/components/Generic/CollapsiblePanel'
import { useTranslate } from '@arfabrands/gatsby-theme-assembly'

const ProductDirections = ({ directions, titleColor, initiallyCollapsed = true }) => {
  const translate = useTranslate()

  return (
    <Flex
      sx={{
        borderBottom: '1px solid',
        borderColor: titleColor,
        py: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '10px'
      }}
    >
      <CollapsiblePanel
        titleColor={titleColor}
        title={translate('product.how_to_use')}
        initiallyCollapsed={initiallyCollapsed}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: directions.childMarkdownRemark.html
          }}
        />
      </CollapsiblePanel>
    </Flex>
  )
}

ProductDirections.propTypes = {
  directions: PropTypes.shape({}),
  titleColor: PropTypes.string,
}

export default ProductDirections
