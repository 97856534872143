import React from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import ProductPage from '~/components/Product/Page'

const constants = {
  backgroundColor: '#ABC5E9',
  navTextColor: '#512773',
  logoColor: '#512773',
}

const ProductTemplate = ({ data }) => {
  const { product } = data

  return (
    <Layout
      backgroundColor={constants.backgroundColor}
      navTextColor={constants.navTextColor}
      logoColor={constants.logoColor}
    >
      <Metadata title={product.name} description={product.shortDescription} />
      <ProductPage product={product} />
    </Layout>
  )
}

export const query = graphql`
  query ProductQuery($slug: String, $locale: String) {
    product: contentfulProduct(
      slug: { eq: $slug }
      node_locale: { eq: $locale }
    ) {
      ...ProductPageFragment
    }
  }
`

export default ProductTemplate
