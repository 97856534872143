/** @jsx jsx */
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Box, Flex, Heading, jsx } from 'theme-ui'
import ProductAddToCartButton from '~/components/Product/AddToCartButton'
import ProductDirections from '~/components/Product/Directions'
import ProductIngredients from '~/components/Product/Ingredients'
import ProductOutOfStock from '~/components/Product/OutOfStock'
import ProductQuantitySelector from '~/components/Product/QuantitySelector'
import ProductOptions from '~/components/Product/Options'
import useProductVariants from '~/hooks/components/use-product-variants'

const ProductDetails = ({
  product,
  collectionName = null,
  handleVariantChange,
  ...props
}) => {
  const {
    longDescription,
    ingredients,
    directions,
    variants,
    optionTypes
  } = product

  const [selectedQuantity, setSelectedQuantity] = useState(1)

  const { currentVariant, selectVariant } = useProductVariants(variants)

  const {
    sku,
    name,
    size,
    comingSoon,
    soldOut,
    price,
    regularPrice
  } = currentVariant

  useEffect(() => {
    handleVariantChange(currentVariant)
  }, [currentVariant, handleVariantChange])

  return (
    <Box
      {...props}
      sx={{
        textAlign: ['left', null, 'left'],
        margin: 'auto',
        marginTop: ['8px', null, '48px'],
        maxWidth: [null, null, '50% !important']
      }}
    >
      {/* {collectionName && (
        <Heading
          as="h3"
          variant="navLink"
          color="primary"
          sx={{
            marginTop: ['16px', null, '0'],
            marginBottom: ['0', null, '12px']
          }}
        >
          {collectionName}
        </Heading>
      )} */}
      <Heading as="h1" variant="h2" sx={{
        color: 'rgb(81, 39, 115)',
        fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
        fontSize: ['34px', '55px'],
        fontWeight: 'normal',
        lineHeight: ['40px', '60px'],
      }}>
        {name}
      </Heading>

      <Heading
        as="h6"
        variant="navLink"
        sx={{
          color: 'rgb(81, 39, 115)',
          fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
          fontSize: '16px',
          fontWeight: 'normal',
          mt: ['8px', null, '12px'],
          mb: ['8px', null, '12px'],
        }}
      >
        {size}
      </Heading>

      <Flex
        py="3"
        sx={{
          flexDirection: 'column'
        }}
      >
        {optionTypes && (
          <Flex
            py="3"
            sx={{
              flexDirection: ['column', 'row'],
              textAlign: 'left'
            }}
          >
            <ProductOptions
              allOptions={optionTypes}
              currentValues={currentVariant.optionValues}
              handleChange={option => selectVariant(option)}
            />
          </Flex>
        )}
        <Flex
          sx={{
            display: 'inline-flex'
          }}
        >
          <ProductQuantitySelector
            handleChange={value => setSelectedQuantity(parseInt(value))}
          />
          <ProductAddToCartButton
            sx={{
              marginTop: ['1rem', null, null],
              marginLeft: ['1rem', null, '16px'],
            }}
            soldOut={soldOut}
            comingSoon={comingSoon}
            price={price}
            quantity={selectedQuantity}
            regularPrice={regularPrice}
            sku={sku}
            pdp={true}
          />
        </Flex>
      </Flex>

      {(soldOut || comingSoon) && <ProductOutOfStock sku={sku} />}

      {longDescription && (
        <div
          sx={{
            color: 'rgb(81, 39, 115)',
            '& p': {
              marginTop: '8px',
              marginBottom: '28px'
            }
          }}
          dangerouslySetInnerHTML={{
            __html: longDescription.childMarkdownRemark.html
          }}
        />
      )}

      {ingredients && <ProductIngredients titleColor={'rgb(81, 39, 115)'} ingredients={ingredients} />}

      {directions && <ProductDirections titleColor={'rgb(81, 39, 115)'} directions={directions} />}
    </Box>
  )
}

ProductDetails.propTypes = {
  product: PropTypes.shape({
    soldOut: PropTypes.bool,
    comingSoon: PropTypes.bool,
    description: PropTypes.shape({}),
    longDescription: PropTypes.shape({}),
    ingredients: PropTypes.shape({}),
    name: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    relatedProducts: PropTypes.array,
    size: PropTypes.string,
    sku: PropTypes.string,
    quote: PropTypes.shape({}),
    directions: PropTypes.shape({}),
    collectionName: PropTypes.string
  }),
  handleVariantChange: PropTypes.func.isRequired
}

export default ProductDetails
