/** @jsx jsx */
import PropTypes from 'prop-types'
import { Flex, jsx } from 'theme-ui'

const ProductRecycle = ({ recycle }) => {
  return (
    <Flex sx={{
      alignItems: 'center',
      backgroundColor: 'rgb(255, 199, 10)',
      justifyContent: 'center',
      padding: '5rem 2rem',
    }}>
      <Flex
        sx={{
          flexDirection: ['column-reverse', 'row'],
          maxWidth: 'calc(1200px + 3rem)',
        }}
      >
        <Flex
          sx={{
            color: 'rgb(81, 39, 115)',
            flexDirection: 'column',
            '& h2': {
              fontFamily: 'GT Walsheim Regular,Helvetica,Arial,Sans-Serif',
              fontSize: '32px',
              fontWeight: 'normal',
              marginBottom: ['1rem', '3rem'],
            },
            '& p': {
              margin: ['1rem 0', '2rem 0'],
            },
            '& strong': {
              fontFamily: '"GT Walsheim Bold", Helvetica, Arial, sans-serif',
            },
            '& a': {
              fontWeight: 'bold',
              textDecoration: 'underline',
            }
          }}
          dangerouslySetInnerHTML={{
            __html: recycle.childMarkdownRemark.html
          }}
        />
        <Flex sx={{
        }}>
          <img
            sx={{
              height: ['inherit', '100%', '100%'],
              width: ['100%', 'inherit', 'inherit'],
            }}
            alt="Cool HIKI character" aria-label="Cool HIKI character" src="https://images.ctfassets.net/kl91l266jlzr/5OIIlaOTll2N0H7UNOcgKv/70ee9e786a309bc5c1b587ba08d54fa0/cool-smaller.gif" />
        </Flex>
      </Flex>
    </Flex>
  )
}

ProductRecycle.propTypes = {
  recycle: PropTypes.shape({}),
}

export default ProductRecycle
